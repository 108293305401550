// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg width=%2723%27 height=%2718%27 viewBox=%270 0 23 18%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Crect width=%2723%27 height=%2718%27 rx=%279%27 fill=%27%23CAD1E1%27/%3E%3Cpath d=%27M8.86962 11.8344L4 9.91295V8.53291L8.86962 6.61147V7.93843L4.48082 9.59448V8.85138L8.86962 10.5074V11.8344Z%27 fill=%27%230F2B5E%27/%3E%3Cpath d=%27M9.15231 14L12.5283 4H13.9503L10.5743 14H9.15231Z%27 fill=%27%230F2B5E%27/%3E%3Cpath d=%27M14.1304 11.8344V10.5074L18.5192 8.85138V9.59448L14.1304 7.93843V6.61147L19 8.53291V9.91295L14.1304 11.8344Z%27 fill=%27%230F2B5E%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*media queries*/
.u-store-tag {
  position: relative;
  text-align: center;
  padding: 8px;
  max-width: 123px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  box-sizing: border-box;
}
.u-store-tag:hover {
  background: #f2f2f2;
  border-radius: 12px;
  text-decoration: none;
}
.u-store-tag.for-dev::after {
  position: absolute;
  content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 23px;
  height: 18px;
  top: 6px;
  right: 36px;
}
.u-store-tag-image {
  display: inline-block;
}
.u-store-tag-image img {
  width: 40px;
  height: 40px;
}
.u-store-tag-title {
  font-size: 12px;
}
.u-store-tag .corporate {
  color: #E98446;
}
.u-store-tag .it-services {
  color: #E64B6A;
}
.u-store-tag .educational {
  color: #3BA68C;
}
.u-store-tag .scientific {
  color: #1E4391;
}
.u-store-tag .financial {
  color: #EF302B;
}
`, "",{"version":3,"sources":["webpack://./src/components/StoreTag.less"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;EACA,sBAAA;AACF;AAAE;EACE,mBAAA;EACA,mBAAA;EACA,qBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,gDAAA;EACA,WAAA;EACA,YAAA;EACA,QAAA;EACA,WAAA;AAEJ;AAAE;EACE,qBAAA;AAEJ;AAHE;EAGI,WAAA;EACA,YAAA;AAGN;AAAE;EACE,eAAA;AAEJ;AAhCA;EAiCI,cAAA;AAEJ;AAnCA;EAoCI,cAAA;AAEJ;AAtCA;EAuCI,cAAA;AAEJ;AAzCA;EA0CI,cAAA;AAEJ;AA5CA;EA6CI,cAAA;AAEJ","sourcesContent":["@import \"../style/const\";\n.u-store-tag {\n  position: relative;\n  text-align: center;\n  padding: 8px;\n  max-width: 123px;\n  margin-left: auto;\n  margin-right: auto;\n  width: 100%;\n  box-sizing: border-box;\n  &:hover {\n    background: lighten(@clr-text, 95%);\n    border-radius: @spacing-s;\n    text-decoration: none;\n  }\n  &.for-dev::after {\n    position: absolute;\n    content: url(\"data:image/svg+xml,%3Csvg width='23' height='18' viewBox='0 0 23 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='23' height='18' rx='9' fill='%23CAD1E1'/%3E%3Cpath d='M8.86962 11.8344L4 9.91295V8.53291L8.86962 6.61147V7.93843L4.48082 9.59448V8.85138L8.86962 10.5074V11.8344Z' fill='%230F2B5E'/%3E%3Cpath d='M9.15231 14L12.5283 4H13.9503L10.5743 14H9.15231Z' fill='%230F2B5E'/%3E%3Cpath d='M14.1304 11.8344V10.5074L18.5192 8.85138V9.59448L14.1304 7.93843V6.61147L19 8.53291V9.91295L14.1304 11.8344Z' fill='%230F2B5E'/%3E%3C/svg%3E \");\n    width: 23px;\n    height: 18px;\n    top: 6px;\n    right: 36px;\n  }\n  &-image {\n    display: inline-block;\n    img {\n      width: 40px;\n      height: 40px;\n    }\n  }\n  &-title {\n    font-size: 12px;\n  }\n  .corporate {\n    color: #E98446;\n  }\n  .it-services {\n    color: #E64B6A;\n  }\n  .educational {\n    color: #3BA68C;\n  }\n  .scientific {\n    color: #1E4391;\n  }\n  .financial {\n    color: #EF302B;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
