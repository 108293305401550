import React from 'react';
import { createRoot } from 'react-dom/client';
import Header from './Header';

function UpperLayout() {
  return (
    <>
      <Header/>
      {/* <Footer/> */}
    </>
  );
}

export default function render() {
  var root = createRoot(document.getElementById('UpperLayout'));
  root.render(<UpperLayout />);
}